import React from 'react';
import styled from 'styled-components';
import { Modal } from '@bojagi/pablo/Modal';
import useKey from 'react-use/esm/useKey';

export interface YouTubeModalProps {
  title: string;
  open: boolean;
  videoId: string;
  onClose: () => void;
}

const VideoWrapper = styled.div`
  width: 80vw;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 56.25%;
  flex-grow: 1;
  flex-shrink: 1;
  cursor: pointer;
`;

const YouTubeIFrame = styled.iframe`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export function YouTubeModal({ title, videoId, onClose, open }: YouTubeModalProps) {
  useKey(event => ['Esc', 'Escape'].includes(event.key), onClose);
  return (
    <Modal open={open} onClose={onClose} maxWidth="full">
      <VideoWrapper>
        {open && (
          <YouTubeIFrame
            title={title}
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </VideoWrapper>
    </Modal>
  );
}

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { breakpoint, getSpacing } from '@bojagi/pablo/styleHelpers';
import { useLogger } from '@bojagi/react-event';

import playBtnImg from '../images/play-btn.svg';
import { YouTubeModal } from '../components/YouTubeModal';

const VideoPlaceholder = styled.div`
  background-color: #867411;
  border-radius: 12px;
  width: 80vw;
  padding-bottom: 56.25%;
  margin-top: ${getSpacing(8)};
  position: relative;
  cursor: pointer;

  background: linear-gradient(180deg, rgba(27, 34, 61, 0.58) 0%, rgba(13, 13, 13, 0.46) 100%),
    url(${props => props.placeholderImage});
  background-size: cover;
  background-position: center;

  ${breakpoint(
      'md',
      css`
        margin-top: 0;
        padding-bottom: 0;
        width: 470px;
        height: 300px;
      `
    )}
    :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  :after {
    content: '';
    width: 92px;
    height: 92px;
    background-image: url(${playBtnImg});
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -46px;
    margin-top: -46px;
  }
`;

export interface IntroductionVideoProps {
  title: string;
  videoId: string;
  placeholderImage: string;
}

export function IntroductionVideo({ title, videoId, placeholderImage }: IntroductionVideoProps) {
  const [videoOpen, setVideoOpen] = useState(false);
  const logger = useLogger();
  return (
    <>
      <VideoPlaceholder
        role="button"
        placeholderImage={placeholderImage}
        onClick={() => {
          setVideoOpen(true);
          logger.info({
            component: 'video',
            action: 'opened',
          });
        }}
      />
      <YouTubeModal
        open={videoOpen}
        onClose={() => {
          setVideoOpen(false);
          logger.info({
            component: 'video',
            action: 'closed',
          });
        }}
        title={title}
        videoId={videoId}
      />
    </>
  );
}

import { Box } from '@bojagi/pablo/Box';
import React, { ReactNode } from 'react';

export interface IntroductionContainerProps {
  left: ReactNode;
  right: ReactNode;
}

export function IntroductionContainer({ left, right }: IntroductionContainerProps) {
  return (
    <Box pt={50} pb={[60, 100]} px={20} mb={[60, 140]} bgColor="#F2F2F2">
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
        maxWidth={1100}
        mx="auto"
      >
        <Box maxWidth={[null, null, 480]}>{left}</Box>
        <Box mt={[6, 6, 0]}>{right}</Box>
      </Box>
    </Box>
  );
}

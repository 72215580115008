import React, { ReactNode } from 'react';
import { Box } from '@bojagi/pablo/Box';
import { Subtitle, Paragraph } from '@bojagi/pablo/Typography';

export interface FeatureProps {
  title: ReactNode;
  image: string;
  imageAltText: string;
  children: ReactNode;
}

export function Feature({ title, image, imageAltText, children }: FeatureProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxWidth={340}
      flexBasis={0}
      mx={[0, 4, 6]}
      mb={[7, 0]}
      flexGrow={1}
    >
      <Box display="flex" alignItems="center" width={150} height={150} borderRadius="50%" mb={5}>
        <img alt={imageAltText} src={image} width="100%" />
      </Box>
      <Subtitle>{title}</Subtitle>
      <Paragraph>{children}</Paragraph>
    </Box>
  );
}

export interface FeatureListProps {
  children: ReactNode;
}

export function FeatureList({ children }) {
  return (
    <Box
      display="flex"
      mx={[0, -4, -6]}
      alignItems={['center', 'flex-start']}
      flexDirection={['column', 'row']}
    >
      {children}
    </Box>
  );
}

import React, { ReactNode } from 'react';
import { Paragraph } from '@bojagi/pablo/Typography';

export interface TitleInfoProps {
  children: ReactNode;
}

export function TitleInfo({ children }: TitleInfoProps) {
  return (
    <Paragraph textColor="gray.500" inline>
      {children}
    </Paragraph>
  );
}
